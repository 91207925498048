.container {
    width: 48.2%;
    margin: 0.5%;
}

.container a {
    text-decoration: none;
}

.container_imagem {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.container_imagem img {
    width: 100%;
    height: 300px;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.overlay h4 {
    color: white;
    font-size: 17px;
    font-weight: 100;
    margin-bottom: 5px;
}