.anuncie_container {
    padding: 2em;
    width: 100%;
}

.anuncie_container h2 {
    text-align: center;
    font-family: 'Bebas Neue';
    font-weight: 100;
    font-size: 30px;
    margin-bottom: 15px;
}

.anuncie_container h2 span {
    color: palevioletred;
}

.anuncie_container h4 {
    text-align: center;
    font-style: italic;
    font-weight: 100;
    color: red;
}

.anuncie_sub_container {
    margin-bottom: 50px;
}

.anuncie_sub_container h3 {
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 10px;
}

.anuncie_sub_container p {
    margin-bottom: 10px;
    font-size: 18px;
}

.anuncie_sub_container_requisito {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.anuncie_sub_container_requisito svg {
    font-size: 17px;
    margin-right: 5px;
    color: palevioletred;
}

.anuncie_sub_container_requisito p {
    font-style: italic;
    font-size: 17px;
    text-decoration: underline;
    margin-bottom: 0;
}

.anuncie_sub_container_verificado {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;

    background-color: rgba(0, 128, 0, .2);
    margin-top: 15px;
    margin-bottom: 15px;
}

.anuncie_sub_container_verificado p {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 0;
}

.anuncie_sub_container_verificado svg {
    margin-right: 15px;
    font-size: 50px;
}

.anuncie_sub_container_btn {
    margin-top: 3em;
    margin-bottom: 10px;
    text-align: center;
    transition: .5s;
}

.anuncie_sub_container_btn img {
    width: 200px;
    height: 50px;
    border-radius: 10px;
}

.anuncie_sub_container_btn:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.anuncie_sub_container_pln p {
    font-size: .9em;
    margin-bottom: 0;
}