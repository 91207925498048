@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#root {
  background-color: rgb(243 244 246)
}

.modal-alert-terms {
  align-items: center;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 10px;
  padding: 1em;
  margin: 50px auto 90px;
}

.modal-alert-terms-content {
  text-align: center;
}

.modal-alert-terms-content p {
  width: 600px;
  margin: 5px auto 10px;
}

.modal-alert-terms-content svg {
  font-size: 70px;
  color: palevioletred;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-close {
  background: transparent;
  color: palevioletred;
  border: none;
  cursor: pointer;
  font-size: 17px;
}

.modal-close:hover {
  color: red;
  transform: scale(1.2);
}