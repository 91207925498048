.testando_p {
    width: 100%;
}

.container_nav_h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: white;
    border-radius: 10px 10px 0 0;
}

.container_nav_h a {
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
    color: #454545;
}

.container_nav_h h4 {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 100;
    font-size: 22px;
}

.container_nav_h h4 span {
    color: palevioletred;
}

.container_nav_h ul {
    display: flex;
    list-style: none;
}

.container_nav_h ul li {
    margin-right: 2px;
}

.suporte {
    margin-right: 15px;
}

.container_nav_v {
    background-color: palevioletred;
    width: 250px;
    min-width: 185px;
    border-radius: 0 0 0 10px;
}

.container_nav_v ul {
    list-style: none;
}

.container_nav_v ul li {
    padding: 10px;
    color: black;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, .9);
    text-align: center;
    cursor: pointer;
    margin: 7px 10px 7px 10px;
    border-radius: 0 0 0 15px;
}

.container_nav_v li:hover {
    background-color: white;
}

.flexcont {
    display: flex;
    align-items: center;
}

.container_nav_v .flexcont svg {
    font-size: 1.2em;
    margin-left: 20px;
    margin-right: 10px;
}

.container {
    padding: 1em;
    width: 100%;
    min-height: 400px;
}

.anuncio_table {
    text-align: center;
    width: 99%;
    color: white;
    border: 1px solid palevioletred;
}

.anuncio_table thead {
    background-color: palevioletred;
}

.anuncio_table thead th {
    padding: 5px;
}

.anuncio_table tbody {
    color: black;
}

.anuncio_table tbody td {
    padding: 5px;
}

.anuncio_table tbody tr:hover {
    cursor: pointer;
    background-color: pink;
}

.perfil {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 20px 10px 20px;
}

.perfil p {
    font-size: 15px;
    font-style: italic;
}

.container_perfil .perfil p a {
    font-size: 1.1em;
    color: #1E90FF;
}

.container_perfil .perfil p a:hover {
    text-decoration: underline;
}

.svg {
    font-size: 50px;
    margin-right: 10px;
    opacity: .7;
}

.container_titler {
    margin: 20px 0 5px 0;
}

.container_titler h2,
.container_titler_home h2,
.container_titler_favorita h2 {
    font-size: 22px;
    font-family: 'Bebas Neue';
    font-weight: 100;
}

.container_titler h2 span,
.container_titler_home h2 span,
.container_titler_favorita h2 span {
    color: palevioletred;
}

.dados {
    display: flex;
    padding: 1em;
    background-color: rgba(255, 255, 255, .65);
    border-radius: 5px;
}

.teste {
    font-size: 18px;
    margin-right: 70px;
}

.teste p {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: 5px;
}

.teste p span {
    font-weight: bold;
}

.teste p select {
    width: 200px;
}

.teste p input {
    margin-left: 5px;
    width: 55px;
    padding: 5px;
    border: 1px solid pink;
}

.teste p input:focus {
    outline: none;
    border: 2px solid palevioletred;
}

.teste_edite {
    font-size: 18px;
    margin-right: 20px;
}

.teste_edite input {
    width: 250px;
}

.teste_edite select {
    padding: .5rem 4rem .7rem 1rem;
    font-size: 15px;
    margin-left: 10px;
    margin-bottom: 5px;
    color: black;
    width: 250px;
    background-color: transparent;
    border: 1px solid pink;
    border-radius: 0;
    box-shadow: none;
}

.teste_edite select:focus {
    border: 2px solid palevioletred;
}

.teste_number {
    font-size: 18px;
    margin-right: 70px;
}

.teste_number p {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: 5px;
}

.teste_number p span {
    font-weight: bold;
}

.teste_number input {
    text-align: center;
    width: 60px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid pink;
}

.teste_number input:focus {
    outline: none;
    border: 2px solid palevioletred;
}

.dados .descricao label {
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

.dados .descricao textarea {
    padding: 10px;
    width: 50vh;
    min-width: 50vh;
    max-width: 100vh;
    height: 10vh;
    max-height: 25vh;
    box-sizing: border-box;
    border: 1px solid pink;
    border-radius: 10px;
}

.dados .descricao textarea:focus {
    outline: none;
    border: 2px solid palevioletred;
}

.container_home_off {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    justify-content: center;
    align-items: center;
    color: pink;
}

.container_home_off svg {
    font-size: 70px;
}

.container_editar {
    text-align: center;
}

.alerta {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: white;
    margin: 50px 25px 30px 25px;
    background-color: rgba(255, 0, 0, .5);
    border-radius: 5px;
}

.alerta svg {
    color: yellow;
    font-size: 1.3em;
    margin-right: 5px;
}

.container_titler_favorita {
    width: 25%;
    border-bottom: 1px solid #696969;
}

.notfound {
    margin-top: 70px;
    text-align: center;
    font-size: 17px;
    font-style: italic;
}

.notfound_svg {
    font-size: 50px;
}

.notfound_actions {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.actions {
    display: flex;
    padding: 10px;
}

.w {
    margin-top: 15px;
    width: 400px;
}

.dados label {
    font-weight: bold;
}

.select_w {
    width: auto;
    margin-left: 5px;
    background: none;
    appearance: auto;
    box-shadow: none;
    border: 1px solid pink;
    color: #454545;
    padding: 2px;
}

.select_w:disabled {
    appearance: none;
    border: none;
    cursor: not-allowed;
}

.input_w {
    text-align: center;
    width: 55px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid pink;
}

.input_w:disabled {
    appearance: none;
    border: none;
    cursor: not-allowed;
}

.input_w:focus {
    outline: none;
    border: 2px solid palevioletred;
}

.adm_anuncios select {
    padding: 8px;
    width: 175px;
    height: 35px;
    font-size: 15px;
    color: black;
    background-color: white;
}

.container_home {
    text-align: center;
}

.container_home img {
    width: 75px;
    height: 75px;
    margin-top: 10px;
}

.container_home h4 {
    margin-bottom: 5px;
}

.container_home p {
    font-style: italic;
}

.container_home_hub {
    background: palevioletred;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
}

.container_home_hub svg,
.container_home_org svg {
    margin-right: 5px;
    margin-bottom: 5px;
}

.container_home_org {
    background: #90EE90;
    color: green;
    padding: 10px;
}

.criaranuncio_container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 1em;
}

.criaranuncio_container h2 {
    font-family: 'Bebas Neue';
    font-weight: 100;
    font-size: 30px;
    padding: 10px;
}

.criaranuncio_container h2 span {
    color: palevioletred;
}

.criaranuncio_container input {
    background-color: white;
    width: 200px;
}

.criaranuncio_container_fotos {
    display: flex;
}

.criaranuncio_container_fotos_desc textarea {
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid pink;
    margin-left: 10px;
    max-width: 410px;
    min-width: 410px;
    min-height: 100px;
    max-height: 185px;
}

.criaranuncio_container_fotos_desc textarea:focus {
    outline: none;
    border: 2px solid palevioletred;
}

.criaranuncio_container_fotoperfil {
    display: flex;
    flex-direction: column;
}

.criaranuncio_container_fotoperfil label,
.criaranuncio_container_fotos_galeria label {
    text-align: center;
    color: white;
    background-color: palevioletred;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.criaranuncio_container input[type="file"] {
    display: none;
}

@media (max-width: 1000px) {
    .dados {
        flex-wrap: wrap;
    }

    .perfil {
        flex-direction: column;
    }

    .teste_edite {
        width: 45%;
        margin-bottom: 15px;
    }
}