.input_container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.input_container label {
    margin-right: 10px;
}

.input_container label svg {
    font-size: 25px;
}

.input_container input {
    padding: 10px;
    outline: none;
    border: 1px solid pink;
    background-color: transparent;
}

.input_container input::placeholder {
    color: #7b7b7b;
}

.input_container input:focus {
    border: 1px solid palevioletred;
}

.input_container input:disabled {
    background-color: rgba(255, 192, 203, .3);
    cursor: not-allowed;
}