.pack_container {
  display: flex;
  margin: 10px;
  max-width: 250px;
  flex-direction: column;
  border-radius: 12px;
  background-color: palevioletred;
  border: 1px solid #fff;
  padding: 1.6rem;
  color: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.header {
  margin: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 0;
  border-bottom: 1px solid #fff;
  background: transparent;
  padding-bottom: 1rem;
  text-align: center;
}

.title {
  display: block;
  font-family: sans-serif;
  font-size: 1.2rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  color: #fff
}

.price_container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  font-family: sans-serif;
  font-size: 2.5rem;
  line-height: 1;
}

.price_container span:first-child {
  margin-top: 10px;
  font-size: 1.5rem;
  line-height: 0.5rem;
}

.price_container span:last-child {
  align-self: flex-end;
  font-size: 1rem;
  line-height: 1.5rem;
}

.lists {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.list span {
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background-color: rgba(255, 255, 255, 0.185);
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list span svg {
  height: 12px;
  width: 12px;
}

.list p {
  color: white;
  display: block;
  font-size: 13.9px;
  font-family: sans-serif;
}

.button_container {
  margin-top: 20px;
  padding: 0;
}

.button_container button {
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #000;
  outline: 0;
  border: 0;
  border-radius: 10px;
  transition: .5s;
}

.button_container button:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.plan_card {
  background: #fff;
  width: 15rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  border-bottom: 4px solid #000446;
  box-shadow: 0 6px 30px rgba(207, 212, 222, 0.3);
  font-family: "Poppins", sans-serif;
}

.plan_card h2 {
  margin-bottom: 15px;
  font-size: 27px;
  font-weight: 600;
}

.plan_card h2 span {
  display: block;
  margin-top: -4px;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
}