.container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 2em;
    min-height: 450px;
}

.container a {
    color: #1E90FF;
}

.container a:hover {
    text-decoration: underline;
}