.container {
    position: relative;
    width: 100%;
}

.imagem_perfil {
    border-radius: 10px;
    border: 2px solid white;
    width: 175px;
    height: 200px;
}

.container svg {
    margin-right: 5px;
}

.menu .actions button svg {
    font-size: 18px;
}

.imagem_capa {
    width: 100%;
    height: 220px;
    border-radius: 10px 10px 0 0;
}

.imagem_galeria {
    display: flex;
    justify-content: center;
    width: 275px;
    height: 320px;
    margin: 5px;
    cursor: pointer;
    overflow: hidden;
    transition: .5s;
}

.profile {
    position: absolute;
    display: flex;
    align-items: center;
    left: 29%;
    transform: translateX(-50%);
    width: 50%;
    height: 3%;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin-left: 240px;
    margin-bottom: 10px;
    width: 75%;
}

.menu h1 {
    font-family: 'Bebas Neue';
    font-weight: 100;
    font-size: 27px;
}

.menu h1 span {
    color: palevioletred;
}

.menu p {
    display: flex;
    align-items: center;
    font-style: italic;
}

.tabela {
    align-items: center;
    justify-content: center;
    padding: 2em;
}

.tabela span {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 15px;
}

.descricao {
    margin-bottom: 60px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.imagem_ampliada_container {
    overflow: auto;
    position: relative;
}

.imagem_ampliada {
    width: auto;
    min-width: 500px;
    max-width: 800px;
    height: auto;
    min-height: 300px;
    max-height: 700px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.botao_fechar {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: black;
    z-index: 999;
}

.botao_fechar:hover {
    color: red;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.follow {
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 1.4em;
}

.container_servico {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    margin-bottom: 60px;
}

.container_servico .colum {
    border-right: 1px solid #696969;
    margin-right: 20px;
    width: 31.5%;
}

.container_servico .colum:nth-child(n + 3) {
    border-right: none;
}

.container_servico .colum p {
    font-size: 15px;
}

.sub_colum {
    display: flex;
}

.sub_colum p {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.modal_container {
    text-align: center;
}

.modal_container h1 {
    color: black;
    font-size: 20px;
}

.modal_container button:nth-child(3) {
    width: 200px;
}

.modal_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 8px;
}

.modal_flex svg {
    margin-left: 5px;
    font-size: 17px;
    transition: .5s;
}

.modal_flex svg:hover {
    color: green;
    cursor: pointer;
}

.modal_flex button {
    border: none;
    background: transparent;
}

.modal_copy {
    font-style: italic;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 8px;
}

@media screen and (max-width: 1000px) {
    .menu {
        width: 70%;
    }

    .menu .actions {
        margin-top: 25px;
    }

    .container_servico .colum {
        width: 45%;
        border-right: none;
        margin-bottom: 50px;
        justify-content: center;
    }

    .container_servico .colum:first-child {
        border-right: 1px solid #696969;
    }
}