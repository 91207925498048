.container {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
}

.min-height {
    min-height: 500px;
}

.start {
    justify-content: flex-start;
}

.start_center {
    justify-content: center;
}

.colum {
    flex-direction: column;
    justify-content: flex-start;
}

.fundo {
    margin-top: 25px;
    margin-bottom: 2em;
    border-radius: 0.5em;
    background-color: rgba(255, 255, 255, .5);
}

.start_favoritas {
    justify-content: flex-start;
    width: 950px;
}

@media (max-width: 1000px) {
    .container {
        width: 900px;
    }
}