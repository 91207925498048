.buttom {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    transition: .5s;
    background-color: palevioletred;
}

.buttom button:disabled {
    background-color: red;  
    cursor: not-allowed;
}

.buttom svg {
    font-size: 1.1em;
    margin-right: 5px;
}

.buttom:hover {
    opacity: .7;
}

.buttom_black {
    background-color: rgba(0, 0, 0, .8);
}

.buttom_red {
    background-color: rgba(255, 0, 0, .75);
}

.buttom_green {
    background-color: rgba(0, 128, 0, .75);
}

.buttom_gold {
    background-color: goldenrod;
}

.buttom_blue {
    background-color: rgba(0, 0, 255, .75);
}